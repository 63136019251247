
import mixins from 'vue-typed-mixins';
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import { mapGetters } from 'vuex';

import DepositData from 'Modules/Payment/components/Deposit/Deposit.Data.vue';
import WalletsApi from 'Apis/Wallets';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

export default mixins(DepositData).extend<any, any, any>({
    mixins: [DepositData],
    components: {
        VueQr,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            time: null,
            showQr: false,
            previousRoute: '/wallets',
            copyTextWithPermissions,
            quotationRate: 0,
            lastQuotationRequest: {
                quotationSymbol: '',
                symbol: '',
            },
            calculatePrecision,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        async getQuotationRate() {
            if (!this.depositUi.asset || !this.quotationAssetSymbol) {
                this.quotationRate = 0;
            }

            try {
                // check for not duplicating requests
                if (
                    this.lastQuotationRequest.quotationSymbol === this.quotationAssetSymbol
                    && this.lastQuotationRequest.symbol === this.depositUi.asset
                ) {
                    return;
                }
                this.lastQuotationRequest = {
                    quotationSymbol: this.quotationAssetSymbol,
                    symbol: this.depositUi.asset,
                };
                const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest(this.lastQuotationRequest));
                this.quotationRate = quotation.rate;
            } catch {
                this.quotationRate = 0;
            }
        },
        showNotification() {
            this.$store.dispatch('Notificator/showSuccessNotification', 'Value Has Successfully Copied To Your Clipboard');
        },
        setTimer(time) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            if (time) {
                if (time < 900000) {
                    this.time = 900000 - time;
                } else {
                    this.time = 0;
                }
            } else {
                this.time = 900000;
            }
            this.intervalId = setInterval(() => {
                if (this.time === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                } else {
                    this.time -= 1000;
                }
            }, 1000);
        },
        validateTimeNumber(num) {
            if (Number(num) < 10) {
                return `0${num}`;
            }
            return String(num);
        },
        async cancelTransferAndHideModal(transferId) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
    },
    async mounted() {
        const { depositUi, address, destinationAddress, previousRoute } = this.$route.query;
        this.depositUi = { ...depositUi, address: new UserTransferAddress(address), destinationAddress: new WalletAddressResponseData(destinationAddress) };
        this.previousRoute = previousRoute;

        await this.getQuotationRate();
    },
    watch: {
        async quotationAssetSymbol() {
            await this.getQuotationRate();
        },
    },
});
